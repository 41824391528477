import Vue from 'vue'
import VueRouter from 'vue-router'
import PinganAna from 'pingansec-vue-ana'
import store from '../store'
import {get_user_info} from '@/api/common'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: resolve => {
      require(['@/views/home/home'], resolve)
    },
    meta: {
      title: "AI竞投-专业的竞价推广数据分析平台",
      // content: {
      //   keywords: 'AI竞投、爱竞投、拓展关键词、扩展关键词、广告创意素材搜索、竞价推广数据分析、竞价推广竞品分析、SEM数据分析、SEM竞品分析、广告创意去哪搜、竞价推广怎么做竞品分析',
      //   description: 'AI竞投专注于SEM领域，实时采集全网竞价推广数据。支持广告创意搜索、关键词扩展、同行竞品分析、公司/关键词监测。创意素材更丰富，关键词更多更优质，广告投放更高效。快速增加流量，有效提升ROI'
      // },
      keepAlive: false,
      fire: 2
    }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: resolve => {
      require(['@/views/home/aboutUs'], resolve)
    },
    meta: {
      title: "关于我们-AI竞投",
      // content: {
      //   keywords: 'AI竞投、爱竞投、AI竞投是哪个公司的、拓展关键词、扩展关键词、广告创意素材搜索、竞价推广数据分析、竞价推广竞品分析、SEM数据分析、SEM竞品分析、广告创意去哪搜、竞价推广怎么做竞品分析',
      //   description: 'AI竞投——专业的竞价推广数据分析平台，是上海凭安网络科技有限公司旗下产品。基于庞大企业网站数据资源和海量关键词库，AI竞投实现了主流搜索引擎全覆盖、智能分析精准搜索、全网推广数据实时采集、广告投放动态监测。'
      // },
      keepAlive: false,
      fire: null
    }
  },
  {
    path: '/claim',
    name: 'claim',
    component: resolve => {
      require(['@/views/home/claim'], resolve)
    },
    meta: {
      title: "AI竞投-会员权限",
      keepAlive: false,
      fire: null
    }
  },
  {
    path: '/box',
    name: 'box',
    component: resolve => {
      require(['@/views/box/box'], resolve)
    },
    children: [
      {
        path: 'findCompetitive',
        name: 'findCompetitive',
        component: resolve => {
          require(['@/views/box/search/findCompetitive'], resolve)
        },
        meta: {
          title: "AI竞投-找竞品",
          keepAlive: false,
          fire: null
        }
      },
      {
        path: 'companyCenter',
        name: 'companyCenter',
        component: resolve => {
          require(['@/views/box/companyCenter/index'], resolve)
        },
        meta: {
          title: "AI竞投-企业中心",
          keepAlive: false,
          fire: null
        }
      },
      {
        path: 'resetPsd',
        name: 'resetPsd',
        component: resolve => {
          require(['@/views/box/resetPsd/index'], resolve)
        },
        meta: {
          title: "AI竞投-密码快速重置",
          keepAlive: false,
          fire: 1
        }
      },
      {
        path: 'personCenter',
        name: 'personCenter',
        component: resolve => {
          require(['@/views/box/personCenter/index'], resolve)
        },
        meta: {
          title: "AI竞投-个人中心",
          keepAlive: false,
          fire: 1
        }
      },
      {
        path: 'compare',
        name: 'compare',
        component: resolve => {
          require(['@/views/box/compare/index'], resolve)
        },
        meta: {
          title: "AI竞投-竞品对比",
          keepAlive: false,
          fire: 1
        }
      },
      {
        path: 'dataView',
        name: 'dataView',
        component: resolve => {
          require(['@/views/box/dataView/index'], resolve)
        },
        meta: {
          title: "AI竞投-首页",
          keepAlive: false,
          fire: 1
        }
      },
      {
        path: 'collection',
        name: 'collection',
        component: resolve => {
          require(['@/views/box/collection/index'], resolve)
        },
        meta: {
          title: "AI竞投-我的收藏",
          keepAlive: false,
          fire: 1
        }
      },
      {
        path: 'search',
        name: 'search',
        component: resolve => {
          require(['@/views/box/search/index'], resolve)
        },
        meta: {
          title: "AI竞投-查广告",
          keepAlive: false,
          fire: 1
        },
        children: [
          {
            path: 'findAdvertising',
            name: 'findAdvertising',
            component: resolve => {
              require(['@/views/box/search/findAdvertising'], resolve)
            }
          },
          {
            path: 'findWord',
            name: 'findWord',
            component: resolve => {
              require(['@/views/box/search/findWord'], resolve)
            }
          },
          {
            path: 'findCompany',
            name: 'findCompany',
            component: resolve => {
              require(['@/views/box/search/findCompany'], resolve)
            }
          },
        ]
      },
      {
        path: 'companyDetails',
        name: 'companyDetails',
        component: resolve => {
          require(['@/views/box/companyDetails/index'], resolve)
        },
        meta: {
          title: "AI竞投-企业详情",
          keepAlive: false,
          //新开页面，获取不到来源，打点写在页面里了
          fire: null
        }
      },
      {
        path: 'export',
        name: 'export',
        component: resolve => {
          require(['@/views/box/export/index'], resolve)
        },
        meta: {
          title: "AI竞投-导出记录",
          keepAlive: false,
          fire: 1
        }
      },
      {
        path: 'dynamic',
        name: 'dynamic',
        component: resolve => {
          require(['@/views/box/monitor/dynamic/index'], resolve)
        },
        meta: {
          title: "AI竞投-监测动态",
          keepAlive: false,
          fire: 1
        },
      },
      {
        path: 'mlist',
        name: 'mlist',
        component: resolve => {
          require(['@/views/box/monitor/list/index'], resolve)
        },
        meta: {
          title: "AI竞投-监测列表",
          keepAlive: false,
          fire: 1
        },
      }]
  },
  {
    path: '/login',
    name: 'login',
    component: resolve => {
      require(['@/views/login/index'], resolve)
    },
    meta: {
      title: "AI竞投-登录页",
      keepAlive: false,
      fire: 2
    }
  },
  {
    path: '/completeInformation',
    name: 'completeInformation',
    component: resolve => {
      require(['@/views/login/completeInformation'], resolve)
    },
    meta: {
      title: "AI竞投-完善信息",
      keepAlive: false,
      fire: 2
    }
  },
  {
    path: '/changeSubject',
    name: 'changeSubject',
    component: resolve => {
      require(['@/views/login/changeSubject'], resolve)
    },
    meta: {
      title: "AI竞投-选择身份",
      keepAlive: false,
      fire: null
    }
  },
  
  
  //移动端h5页面
  
  {
    path: '/h5',
    name: 'home_h5',
    component: resolve => {
      require(['@/views/h5/home'], resolve)
    },
    meta: {
      title: "AI竞投-专业的竞价推广数据分析平台",
      // content: {
      //   keywords: 'AI竞投、爱竞投、拓展关键词、扩展关键词、广告创意素材搜索、竞价推广数据分析、竞价推广竞品分析、SEM数据分析、SEM竞品分析、广告创意去哪搜、竞价推广怎么做竞品分析',
      //   description: 'AI竞投专注于SEM领域，实时采集全网竞价推广数据。支持广告创意搜索、关键词扩展、同行竞品分析、公司/关键词监测。创意素材更丰富，关键词更多更优质，广告投放更高效。快速增加流量，有效提升ROI'
      // },
      fire: 2
    }
  },
  {
    path: '/aboutUs_h5',
    name: 'aboutUs_h5',
    component: resolve => {
      require(['@/views/h5/aboutUs'], resolve)
    },
    meta: {
      title: "关于我们-AI竞投",
      // content: {
      //   keywords: 'AI竞投、爱竞投、AI竞投是哪个公司的、拓展关键词、扩展关键词、广告创意素材搜索、竞价推广数据分析、竞价推广竞品分析、SEM数据分析、SEM竞品分析、广告创意去哪搜、竞价推广怎么做竞品分析',
      //   description: 'AI竞投——专业的竞价推广数据分析平台，是上海凭安网络科技有限公司旗下产品。基于庞大企业网站数据资源和海量关键词库，AI竞投实现了主流搜索引擎全覆盖、智能分析精准搜索、全网推广数据实时采集、广告投放动态监测。'
      // },
      fire: null
    }
  },
  {
    path: '/claim_h5',
    name: 'claim_h5',
    component: resolve => {
      require(['@/views/h5/claim'], resolve)
    },
    meta: {
      title: "AI竞投-会员权限",
      keepAlive: false,
      fire: null
    }
  },
  {
    path: '/login_h5',
    name: 'login_h5',
    component: resolve => {
      require(['@/views/h5/login'], resolve)
    },
    meta: {
      title: "AI竞投-登录",
      fire: 2
    }
  },
  {
    path: '/protocol',
    name: 'protocol',
    component: resolve => {
      require(['@/views/h5/protocol'], resolve)
    },
    meta: {
      title: "AI竞投-用户协议",
      fire: null
    }
  },
  {
    path: '/monitor_h5',
    name: 'monitor_h5',
    component: resolve => {
      require(['@/views/h5/monitor'], resolve)
    },
    meta: {
      title: "AI竞投-监测动态",
      fire: null
    }
  },
  {
    path: '/monitor_nodata_h5',
    name: 'monitor_nodata_h5',
    component: resolve => {
      require(['@/views/h5/monitorNoData'], resolve)
    },
    meta: {
      title: "AI竞投-监测动态",
      fire: null
    }
  },
  {
    path: '/blank',
    name: 'blank',
    component: resolve => {
      require(['@/views/h5/WXblank'], resolve)
    },
    meta: {
      title: "AI竞投",
      fire: null
    }
  },
  {
    path: '/blank2',
    name: 'blank2',
    component: resolve => {
      require(['@/views/h5/WXblank2'], resolve)
    },
    meta: {
      title: "AI竞投",
      fire: null
    }
  },
  {
    path: '/compare_h5',
    name: 'compare_h5',
    component: resolve => {
      require(['@/views/h5/compare'], resolve)
    },
    meta: {
      title: "AI竞投-竞品PK",
      fire: null
    }
  },
  {
    path: '/share_h5',
    name: 'share_h5',
    component: resolve => {
      require(['@/views/h5/share'], resolve)
    },
    meta: {
      title: "竞品pk-AI竞投",
      description: '',
      fire: null
    }
  },
  {
    path: '/share_h5_a',
    name: 'share_h5_a',
    component: resolve => {
      require(['@/views/h5/shareA'], resolve)
    },
    meta: {
      title: "竞品pk-AI竞投",
      description: '',
      fire: null
    }
  },
  {
    path: '/share_h5_b',
    name: 'share_h5_b',
    component: resolve => {
      require(['@/views/h5/shareB'], resolve)
    },
    meta: {
      title: "竞品pk-AI竞投",
      description: '',
      fire: null
    }
  },
  {
    path: '/share_h5_c',
    name: 'share_h5_c',
    component: resolve => {
      require(['@/views/h5/shareC'], resolve)
    },
    meta: {
      title: "竞品pk-AI竞投",
      description: '',
      fire: null
    }
  },
  {
    path: '/share_h5_jump',
    name: 'share_h5_jump',
    component: resolve => {
      require(['@/views/h5/shareJump'], resolve)
    },
    meta: {
      title: "竞品pk-AI竞投",
      description: '',
      fire: null
    }
  },
  {
    path: '/monitor_message',
    name: 'monitorMessage',
    component: resolve => {
      require(['@/views/h5/monitorMessage'], resolve)
    },
    meta: {
      title: "AI竞投",
      fire: null
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

const fire = function (to, from) {
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  let platform = flag?'"side":"h5"':'"side":"pc"'
  const sourceTurn = function(item) {
    let sourceMap = new Map([
      ['aisou_banner', 1],
      ['aisou_detail', 2],
      ['message', 3],
    ]);
    return sourceMap.get(item)
  };
  PinganAna.ready(function () {
    if(to.meta.fire == 1) {
      PinganAna.userClickLog(
        '页面浏览',
        '{"pageName":"'+to.name+'","页面中文":"'+to.meta.title+'","pageSource":"'+from.name+'",'+platform+'"}',
        '【AI竞投】系统',
        store.state.userInfo.vip_type == 5?3:store.state.userInfo.vip_type == 10?1:2,
        '--',
        store.state.userInfo.account
      )
    } else if(to.meta.fire == 2) {
      PinganAna.userClickLog(
        '页面浏览',
        '{"pageName":"'+to.name+'","页面中文":"'+to.meta.title+'","pageSource":"'+from.name+'",'+platform+','+'"activity":"'+sourceTurn(localStorage.getItem('ajt_source'))+'"'+'}',
        '【AI竞投】系统',
        4,
        '--'
      )
    }
  })
}

router.beforeEach((to, from, next) => {
  // if(process.env.VUE_APP_CONSOLE==='vsconsole'){
  //   var vsconsole = require('vconsole');
  //   new vsconsole();
  // }
  // if (to.meta.content) {
  //   const head = document.getElementsByTagName('head')
  //   const meta = document.createElement('meta')
  //   document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
  //   document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
  //   meta.content = to.meta.content
  //   head[0].appendChild(meta)
  // };
  
  if (to.meta.title) {
    document.title = to.meta.title
  };
// 为每个页面适配宽度
  switch (to.path) {
    case '/h5':
      document.body.style.setProperty('--min-width','');
      break;
    case '/aboutUs_h5':
      document.body.style.setProperty('--min-width','');
      break;
    case '/login_h5':
      document.body.style.setProperty('--min-width','');
      break;
    case '/compare_h5':
      document.body.style.setProperty('--min-width','');
      break;
    case '/share_h5':
      document.body.style.setProperty('--min-width','');
      break;
    case '/share_h5_a':
      document.body.style.setProperty('--min-width','');
      break;
    case '/share_h5_b':
      document.body.style.setProperty('--min-width','');
      break;
    case '/share_h5_c':
      document.body.style.setProperty('--min-width','');
      break;
    
    case '/protocol':
      document.body.style.setProperty('--min-width','');
      break;
    default:
      document.body.style.setProperty('--min-width','1200px');
  }
  // 记录用户行为
  if(to.meta.fire == 1 && (typeof(store.state.userInfo.vip_type) === 'undefined' || typeof(store.state.userInfo.account) === 'undefined')) {
    get_user_info().then(res => {
      if(res.data.result_code == 0) {
        store.state.userInfo = res.data.data
        fire(to, from)
      } else {
        if(process.env.NODE_ENV === 'development') {
          console.error('打点失败')
        }
      }
    })
  } else {
    fire(to, from)
  }
  next()
  
  if (to.path && to.matched && to.matched.length>0) {
    PinganAna.ready(function () {
      let pageName = to.matched[to.matched.length-1].path;
      PinganAna.autoTrack(pageName);
    });
  }
})

export default router

import Vue from 'vue';

import {
    get_user_info
} from '@/api/common'
const GOBAL = new Vue({
    data() {
        return {
            userInfoCallBack: [],
            userInfoStatus: 'start',
            userInfo: [],
            userInfoiTimer: null,
        }
    },
    methods: {
        getUserInfo() {
            return new Promise((res, rej) => {
                if (this.userInfoStatus == 'pending') {
                    this.userInfoCallBack.push(res)
                } else if (this.userInfoStatus == 'reslove') {
                    res(this.userInfo)
                } else if (this.userInfoStatus == 'start') {
                    this.userInfoStatus = 'pending';
                    this.userInfoCallBack.push(res)

                    get_user_info().then(res => {
                        this.userInfo = res.data.data
                        this.userInfoStatus = 'reslove'
                        for (let i = 0; i < this.userInfoCallBack.length; i++) {
                            this.userInfoCallBack[i](this.userInfo)
                        }
                    })
                }
            });
        },
    }
})

export default {
    GOBAL,
    install(Vue) {
        Vue.prototype.$G = GOBAL;
    }
}
<template>
  <div class="layout" :style="style">
    <div class="layout_content">
      <div class="layout_header" @touchmove.prevent>
        <slot name="header" />
      </div>
      <div class="layout_body" ref="layout_body">
        <slot name="body" />
      </div>
      <div class="layout_footer" @touchmove.prevent>
        <slot name="footer" />
      </div>
      <slot />
    </div>
  </div>
</template>
<script>
import { px2rem, isApp } from "@/public/util";
export default {
  name: "layout",
  data() {
    return {
      style: isApp()?`height:100vh`:`height:${window.innerHeight}px` 
    };
  },
  created(){

  }
};
</script>
<style lang="scss" scoped>
.layout {
  width: 100vw;
  user-select: none;
  .layout_content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    .layout_header,
    .layout_footer {
      flex-shrink: 0;
    }
    .layout_body {
      flex: 1;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      position: relative;
    }
  }
}
</style>
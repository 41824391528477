<template>
  <div id="dialog" @click.self="closeFn">
    <div class="dialog" :style="{ width: width + 'px' }">
      <div class="header">
        <span>{{ title }}</span>
        <img
          @click.self="closeFn"
          src="@/assets/img/icon/close.png"
          alt=""
        />
      </div>
      <div
        class="content scrollbar-class"
        :style="{ width: contentWidth, margin: contentMargin }"
      >
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["title", "width", "contentWidth", "contentMargin"],
  methods: {
    closeFn() {
      this.$emit("closeFn");
    },
  },
};
</script>

<style scoped lang="scss" src="@/components/dialogs/dialog.scss"></style>
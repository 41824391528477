import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    detailTabElIndex: 0,
    headerMsg: '',
    searchType: 'findAdvertising',
    userInfo: {},
  },
  mutations: {
    changeDTEI(state, index) {
      state.detailTabElIndex = index
    },
    changeMsg(state, msg) {
      state.headerMsg = msg
    },
  },
  actions: {},
  modules: {}
})

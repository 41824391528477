import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import gobal from './utils/gobal'
import common from './utils/common'
import DialogBox from './components/dialogs/dialog'
import Page from './components/page'
import Button from './components/myButton/myButton'
import NoData from './components/noData'
import Mylayout from '@/components/layout/layout'
import './utils/flexible'
import drag from '@/utils/drag';


Vue.use(gobal).use(common)
Vue.component('DialogBox', DialogBox)
Vue.component('Page', Page)
Vue.component('ButtonBox', Button)
Vue.component('NoData', NoData)
Vue.component("my-layout", Mylayout)


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import {Toast} from 'vant';
// import axios from '@/public/axios';
const typeList = [
  "String",
  "Array",
  "Undefined",
  "Boolean",
  "Number",
  "Function",
  "Symbol",
  "Object"
];
const mailReg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
export const isApp = _ => {
  return process.env.NODE_ENV === 'development' || navigator.userAgent.indexOf("Html5Plus") != -1;
  // return navigator.userAgent.indexOf("Html5Plus") != -1;
};
export const getStorage = key => window.localStorage.getItem(key) ? JSON.parse(window.localStorage.getItem(key)) : null;
export const setStorage = (key, val) => window.localStorage.setItem(key, JSON.stringify(val));
export const removeStorage = key => window.localStorage.removeItem(key);
export const addStorage = (key, val) => {
  let obj = getStorage(key);
  setStorage(key, obj && JSON.stringify(obj) != '{}' ? obj.push(val) : [val])
};
export const getState = _ => window.sessionStorage.getItem("state") ? JSON.parse(window.sessionStorage.getItem("state")) : {};
export const setState = state => window.sessionStorage.setItem('state', JSON.stringify(state));


export const type = s =>
  Object.prototype.toString
  .call(s)
  .slice(8, -1)
  .toLowerCase();

export const types = typeList.reduce((acc, str) => {
  acc["is" + str] = val => type(val) === str.toLowerCase();
  return acc;
}, {});
/**
 * 1080尺寸转px
 * @param {Number} no
 */
export const calculatedSize = no => {
  let rem = window['lib']['flexible'].rem;
  let rootValue = 108;
  no = Number(no);
  return no > 2 ? (rem / rootValue * no).toFixed(2) : no;
}
/**
 * 1080尺寸转rem
 * @param {Number} no
 */
export const px2rem = no => {
  let rem = window['lib']['flexible'].rem;
  let rootValue = 108;
  no = Number(no);
  return window['lib']['flexible'].px2rem(no > 2 ? (rem / rootValue * no) : no)
};
/**
 * 克隆
 * @param {*} obj 
 */
export const deepClone = function (obj) {
  if (!obj) {
    return obj
  }
  var newObj = obj.constructor == Array ? [] : {};
  for (var i in obj) {
    if (obj.hasOwnProperty(i)) {
      if (typeof obj[i] === 'object') {
        newObj[i] = deepClone(obj[i])
      } else {
        newObj[i] = obj[i];
      }
    }
  }
  return newObj
}
/**
 * 将a标签转为span
 * @param {String} text 
 */
export const buildA = text => {
  return text ?
    String(text)
    .replace(/<a[\s\S]*?>/gi, '<span>')
    .replace(/<\/\s?a>/gi, '</span>') :
    "-";
}

export const trim = text => text ? text.replace(/(^\s*)|(\s*$)/g, '') : '';

export const buildText = (text, placeholder) => String(text) == 'undefined' || String(text) == 'null' || String(text) === '' ? placeholder || '' : text;

export const getCss = (curEle, attr) => {
  let val = null;
  try {
    val = window.getComputedStyle(curEle, null)[attr];
  } catch (e) {
    val = curEle.currentStyle[attr];
  }
  return val;
}

export const getRandomColor = (str) => {
  let bgArr = ['#3aa678', '#c17b56', '#4c79bd', '#b16db0', '#ac6767'];
  let index = 0;
  if (str) {
    index = String(str.charCodeAt(0)).split("").find(i => i <= bgArr.length);
  }
  return bgArr[index || Math.floor(Math.random() * bgArr.length)];
}


export const dateFormat = (fmt, date) => {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString() // 秒
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    }
  }
  return fmt;
}

export const resetScroll = _ => {
  document.addEventListener("touchstart", e => {
    let target = e.target || e.srcElement;
    if (target.nodeName.toLowerCase() != 'input') {
      var inputs = document.querySelectorAll("input");
      inputs.forEach(item => {
        item.blur();
      });
    }
  })
}

export const filterNo = (no, max) => {
  max = max || 999
  no = Number(no)
  return no > max ? `${max}+` : no
}

export const isLegalEmail = (str) => {
  return mailReg.test(str)
}

export const filterType = (str) => {
  switch (str) {
    case "注销":
      return "danger";
    case "迁出":
      return "danger";
    case "在业":
      return "default";
    case "存续":
      return "default";
    default:
      return "default";
  }
}

export function copyText(text) {
  if (plus.os.name == "Android") {
    var Context = plus.android.importClass("android.content.Context");
    var main = plus.android.runtimeMainActivity();
    var clip = main.getSystemService(Context.CLIPBOARD_SERVICE);
    plus.android.invoke(clip, "setText", text);
    Toast.success('已成功复制到剪贴板');
  } else if (plus.os.name == "iOS") {
    var UIPasteboard = plus.ios.importClass("UIPasteboard");
    var generalPasteboard = UIPasteboard.generalPasteboard();
    //设置/获取文本内容:
    generalPasteboard.plusCallMethod({
      setValue: text,
      forPasteboardType: "public.utf8-plain-text"
    });
    generalPasteboard.plusCallMethod({
      valueForPasteboardType: "public.utf8-plain-text"
    });
    Toast.success('已成功复制到剪贴板');
  }
}
export function shareWXweb(scene, title, content, url) {
  var shares = null;
  plus.share.getServices(function (s) {
    shares = s[0]; //用户需要保存返回的分享对象。在调用分享和授权接口时需要使用到该对象。

    //console.log(JSON.stringify(s))
    //[{"id":"weixin","description":"微信","authenticated":true,"accessToken":"","nativeClient":true}]

    if (shares.id == "weixin") {
      if (shares.authenticated) { //已授权    
        var msg = {
          type: 'web',
          title: title,
          content: content,
          thumbs: ['http://staticcdn.shuidi.cn/shuidi/images/cha/logo-app.jpg'],
          //pictures:['http://staticcdn.shuidi.cn/shuidi/images/cha/logo-app.png'],
          href: url,
          extra: {
            scene: scene
          },
        };
        //我的好友：WXSceneSession
        //朋友圈：WXSceneTimeline

        shares.send(msg, function () {
          Toast.success(`分享到${shares.description}成功！`);
        }, function (e) {
          Toast.fail(`分享到${shares.description}失败${e.code}${e.message}`);
        });
      }
    } else { //未授权
      shares.authorize(function () {
        console.log("认证完成！");

        //同上
      }, function (e) {
        console.log("未进行认证");
      });
    }
  }, function (e) {
    console.log(("获取分享服务列表失败：" + JSON.stringify(e)))
  });
}
export function shareWX(scene, picture) {
  //1、获取分享服务对象列表，目前只开通了微信
  var shares = null;
  plus.share.getServices(function (s) {
    shares = s[0]; //用户需要保存返回的分享对象。在调用分享和授权接口时需要使用到该对象。

    //console.log(JSON.stringify(s))
    //[{"id":"weixin","description":"微信","authenticated":true,"accessToken":"","nativeClient":true}]

    if (shares.id == "weixin") {
      //2、用户分享信息前需要对分享平台是否授权过进行判断，如果已经授权则可直接分享消息，
      //如没有授权则需要调用已保存的分享对象的“authorize”接口对客户端进行授权。授权成功后再调用分享接口分享消息。
      if (shares.authenticated) { //已授权

        //3、对客户端授权成功之后用户可以调用分享对象的“send”方法发送要分享的消息，用户创建JSON对象设置要分享的数据。
        //用户如需要在分享信息中插入图片，可以在消息中添加pictures节点，属性值为图片地址，图片地址可以为网络地址或者本地图片地址。

        var msg = {
          // content: 'test13162177189',
          type: 'image',
          extra: {
            scene: scene
          },
          pictures: [picture]
        };
        //我的好友：WXSceneSession
        //朋友圈：WXSceneTimeline

        shares.send(msg, function () {
          Toast.success(`分享到${shares.description}成功！`);
        }, function (e) {
          Toast.fail(`分享到${shares.description}失败${e.code}${e.message}`);
        });
      }
    } else { //未授权
      shares.authorize(function () {
        console.log("认证完成！");

        //同上
      }, function (e) {
        console.log("未进行认证");
      });
    }
  }, function (e) {
    console.log(("获取分享服务列表失败：" + JSON.stringify(e)))
  });
}
export function shareWXcomp(scene, digest, title, content, url) {
  var shares = null;
  plus.share.getServices(function (s) {
    shares = s[0]; //用户需要保存返回的分享对象。在调用分享和授权接口时需要使用到该对象。

    if (shares.id == "weixin") {
      if (shares.authenticated) { //已授权    
        var msg = {
          type: 'web',
          title: title,
          content: content,
          thumbs: ['https://shuidi.cn/company-text-logo-' + digest + '.jpg'],
          href: url,
          extra: {
            scene: scene
          },
        };

        shares.send(msg, function () {
          Toast.success(`分享到${shares.description}成功！`);
        }, function (e) {
          Toast.fail(`分享到${shares.description}失败${e.code}${e.message}`);
        });
      }
    } else { //未授权
      shares.authorize(function () {
        console.log("认证完成！");

        //同上
      }, function (e) {
        console.log("未进行认证");
      });
    }
  }, function (e) {
    console.log(("获取分享服务列表失败：" + JSON.stringify(e)))
  });
}

export function throttle(fn, interval) {
  var enterTime = 0;
  var gapTime = interval || 500;
  return function () {
    var backTime = +new Date();
    if (backTime - enterTime > gapTime) {
      fn.call(this, arguments);
      enterTime = backTime;
    }
  };
}
export function validImage(src) {
  return new Promise((resolve, reject) => {
    try {
      let imgObj = new Image();
      imgObj.src = src;
      imgObj.onload = res => {
        resolve(res)
      }
      imgObj.onerror = err => {
        reject(err)
      }
    } catch (e) {
      reject(err)
    }
  })
}
export function buildImageUrl(path, w, h, q) {
  if(/^http/.test(path)){return path}
  w = w || 0;
  h = h || 0;
  let src = `${process.env.VUE_APP_UPLOAD_FILE_PATH}/img/${path}/${w}x${h}.png`;
  return q ? `${src}?q=${q}` : src;
}

export function newDateFormat(time, fmt) {
  if (!time) {
    return ''
  }
  if ((typeof time === 'string')) {
    if ((/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    } else {
      time = time.replace(new RegExp(/-/gm), '/') // 兼容下ios
    }
  }
  if ((typeof time === 'number') && (time.toString().length === 10)) {
    time = time * 1000
  }
  let timezone = 8; //目标时区时间，东八区
  let offset_GMT = new Date().getTimezoneOffset();
  let date = new Date(+new Date(time) + offset_GMT * 60 * 1000 + timezone * 60 * 60 * 1000);

  fmt = fmt || "yyyy-mm-dd"
  const opt = {
    "y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString() // 秒
  };
  for (let k in opt) {
    let ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
    };
  };
  return fmt;
}

export const provinces =()=>{
  return [{"text":"全国","value":"全国"},{"text":"广东","value":"广东"},{"text":"北京","value":"北京"},{"text":"江苏","value":"江苏"},{"text":"上海","value":"上海"},{"text":"浙江","value":"浙江"},{"text":"四川","value":"四川"},{"text":"山东","value":"山东"},{"text":"河北","value":"河北"},{"text":"河南","value":"河南"},{"text":"福建","value":"福建"},{"text":"湖北","value":"湖北"},{"text":"安徽","value":"安徽"},{"text":"重庆","value":"重庆"},{"text":"陕西","value":"陕西"},{"text":"湖南","value":"湖南"},{"text":"辽宁","value":"辽宁"},{"text":"天津","value":"天津"},{"text":"江西","value":"江西"},{"text":"云南","value":"云南"},{"text":"广西","value":"广西"},{"text":"山西","value":"山西"},{"text":"贵州","value":"贵州"},{"text":"吉林","value":"吉林"},{"text":"黑龙江","value":"黑龙江"},{"text":"内蒙古","value":"内蒙古"},{"text":"新疆","value":"新疆"},{"text":"甘肃","value":"甘肃"},{"text":"海南","value":"海南"},{"text":"宁夏","value":"宁夏"},{"text":"青海","value":"青海"},{"text":"西藏","value":"西藏"}];
} 

export const getColor=()=> {
  const color_list = ['rgba(58,166,120,0.6)','rgba(193,123,86,0.6)','rgba(76,121,189,0.6)','rgba(177,109,176,0.6)','rgba(172,103,103,0.6)'];
  const index = Math.floor(Math.random()*color_list.length);
  return color_list[index];
}
/**
 * 是否机器人，爬虫
 */
export function Robot(){
  try {
    if (navigator.webdriver) {
      return true;
    }
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('testRobot', '1');
      if (!localStorage.getItem('testRobot')) {
        return true;
      }
      localStorage.removeItem("testRobot");
    }
    if (navigator.userAgent.toLowerCase().indexOf('spider') !== -1) {
      return true;
    }
  }catch (e) {
    return false;
  }
  return false;
}

export const CompanyList = (company_name,index) =>{//index用于获取最新的下拉列表，防止网络延时
  if(company_name.length>=2){
    return new Promise((resolve) =>{
      axios.http.get('/',{
        params:{
          "action": "company_name_notice",
          "company_name": company_name
        }
      }).then(res =>{
        if(res.data && res.data.company_list){
          var list = res.data.company_list 
          var obj = null          
          if(list.length > 5){
            list.splice(5, list.length - 5)             
          }
          for(var i=0; i<list.length; i++){
            if(company_name == list[i].company_name){//下来列表出现的公司与输入公司一样
              obj = list[i]
            }
          } 
          resolve({
            'index':index?index:0,
            'list':list,
            'obj': obj
          });  
        } else {
          return Promise.reject();
        }
      })
    })  
  } else {
    return Promise.reject();
  }  
}
<!--提醒用户升级各种权限的通用弹窗-->
<template>
    <div class="common_dialog">
        <el-dialog
            :title="title"
            :visible.sync="visible"
            :before-close="handleClose">
            <template #title>
                <div v-if="type == 5 || type == 10" class="vip_dialog_header">
                    <img class="logo" src="../../assets/img/dialog/vip_logo.png"/>
                    <span class="text">标准版会员特权</span>
                </div>
                <div v-if="type == 20" class="svip_dialog_header">
                    <img class="logo" src="../../assets/img/dialog/svip_logo.png"/>
                    <span class="text">至尊版会员特权</span>
                </div>
            </template>
            <div class="dialog_content">
                <span class="info">{{text}}</span>
                <myButton type="primary" width="80" height="33"  @click="handleClose">
                    <template #text>
                        <span>确 定</span>
                    </template>
                </myButton>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import myButton from "../myButton/myButton";
import PinganAna from "pingansec-vue-ana";
  export default {
    name: "common_dialog",
    props: {
      title: null,
      text: String,
      visible: {
        type: Boolean,
        default: false
      },
      width: String,
    },
    components: {
      myButton
    },
    computed: {
      type() {
        return this.$store.state.userInfo.vip_type;
      },
    },
    data() {
      return {
      }
    },
    watch: {
      visible(val, old) {
        if(!old && val) {
          PinganAna.ready(() => {
            PinganAna.userClickLog(
              '非会员，权限提示对话展示',
              '{"info":"'+this.text+'"}',
              '【AI竞投】系统',
              this.$store.state.userInfo.vip_type == 5?3:this.$store.state.userInfo.vip_type == 10?1:2,
              '--',
              this.$store.state.userInfo.account
            )
          })
        }
      }
    },
    methods: {
      handleClose() {
        this.$emit("beforeClose");
      }
    }
  }
</script>

<style lang="scss">
.common_dialog {
    .el-dialog {
        width: 485px;
        border-radius: 5px;
        overflow: hidden;
        .el-dialog__headerbtn .el-dialog__close {
            color: rgba(0,0,0,0.3);
        }
        .el-dialog__header {
            padding: 0;
            .logo {
                width: 120px;
                height: 95px;
            }
            .text {
                font-size: 20px;
                color: #fff;
            }
            .vip_dialog_header {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 128px;
                background: url("../../assets/img/dialog/vip_bg.png") center no-repeat;
                background-size: cover;
            }
            .svip_dialog_header {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 128px;
                background: url("../../assets/img/dialog/svip_bg.png") center no-repeat;
                background-size: cover;
            }
        }
        .el-dialog__body {
            padding: 36px 20px;
            .dialog_content {
                display: flex;
                flex-direction: column;
                align-items: center;
                .info {
                    font-size: 14px;
                    color: #333333;
                    margin-top: 3px;
                    margin-bottom: 25px;
                    line-height: 20px;
                }
            }
        }
    }
}
</style>

<template>
  <div id="page">
    <el-pagination
      v-if="refresh"
      background
      @size-change="limitChange"
      @current-change="pageChange"
      :current-page.sync="config.page"
      :page-sizes="config.sizes"
      :page-size="config.limit"
      :layout="config.layout || 'sizes, prev, pager, next'"
      :total="config.total"
      :pager-count="config.count"
    >
    </el-pagination>

    <common_dialog
      @beforeClose="dialogShow = false"
      :visible.sync="dialogShow"
      :text="text"
      width="40%">
    </common_dialog>
  </div>
</template>

<script>
import common_dialog from "./dialogs/common_dialog";
export default {
  data() {
    return {
      dialogShow: false,
      refresh: true,
      text: ''
    };
  },
  components: {
    common_dialog
  },
  props: {
    config: {
      type: Object,
    },
  },
  methods: {
    reload() {
      this.refresh = false
      setTimeout(() => {
        this.refresh = true
      }, 0);
    },
    limitChange(val) {
      if(this.$store.state.userInfo.vip_type == 10) {
        if(this.config.page * val <= 50) {
          this.$emit("limitChange", val);
        } else {
          this.dialogShow = true;
          this.text = 'VIP会员可查看全部结果，请联系您的客户经理开通'
          this.$emit("limitChange", 10);
        }
      } else if(this.$store.state.userInfo.vip_type == 5) {
        this.dialogShow = true;
        this.text = 'VIP会员可查看全部结果，请联系在线客服开通'
        this.reload()
      } else {
        this.$emit("limitChange", val);
      }
    },
    pageChange(val) {
      if(this.$store.state.userInfo.vip_type == 10) {
        if(this.config.page * this.config.limit <= 50) {
          this.$emit("pageChange", val);
        } else {
          this.dialogShow = true;
          this.text = 'VIP会员可查看全部结果，请联系您的客户经理开通'
          this.$emit("limitChange", 10);
        }
      } else if(this.$store.state.userInfo.vip_type == 5) {
        this.dialogShow = true;
        this.text = 'VIP会员可查看全部结果，请联系在线客服开通'
        this.config.page = 1
        this.reload()
      } else {
        this.$emit("pageChange", val);
      }
    }
  },
};
</script>
<style lang="scss">
#page {
  @extend .width-normal;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  .btn-prev,
  .el-icon-d-arrow-left,
  .btn-next,
  .el-icon-d-arrow-right,
  .more,
  .number {
    height: 26px;
    background-color: rgba(255, 55, 255, 0) !important;
    border-radius: $r3;
    border: solid 1px $cebeff2;
    line-height: 26px;
    color: $c666666;
    margin: 0 3px;
    font-weight: normal;
  }
  .active {
    border-color: #1f81f8;
  }
  .el-input--suffix {
    margin: 0;
  }
  .el-input__inner {
    height: 26px;
    line-height: 26px;
  }
  .el-pagination__sizes {
    height: 26px;
    line-height: 26px !important;
  }
  .el-input__suffix {
    top: 1px;
  }
}
.el-select-dropdown__item.selected {
  font-weight: unset !important;
}
</style>

<template>
  <div id="app" class="scrollbar-class" ref="appContent" @mousewheel="scroll">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <div class="ajt_server"
      v-if="!flag">
      <div class="item" @mouseenter="show_qun = true" @mouseleave="show_qun = false">
        <img class="default_img" src="@/assets/img/index/icon_1.png" width="23" height="25" alt="">
        <img class="active_img" src="@/assets/img/index/icon_1_a.png" width="23" height="25" alt="">
        <span>行业</span>
        <span>交流群</span>
        <div class="qun_code" v-if="show_qun">
          <img :src="code_url" alt="">
          <span>微信扫码加入</span>
          <span>竞价推广行业交流群</span>
        </div>
      </div>
      <div class="item" @mouseenter="show_ma = true" @mouseleave="show_ma = false">
        <img class="default_img" src="@/assets/img/index/icon_2.png" width="26" height="21" alt="">
        <img class="active_img" src="@/assets/img/index/icon_2_a.png" width="26" height="21" alt="">
        <span>微信</span>
        <span>公众号</span>
        <div class="QR_code" v-if="show_ma"></div>
      </div>
      <div class="item" @click="handleServer">
        <img class="default_img" src="@/assets/img/index/icon_3.png" width="22" height="25" alt="">
        <img class="active_img" src="@/assets/img/index/icon_3_a.png" width="22" height="25" alt="">
        <span>在线</span>
        <span>咨询</span>
      </div>
    </div>
  </div>
</template>


<script>
import Bus from "@/utils/bus";
export default {
  data() {
    return {
      flag: false,
      show_ma: false,
      show_qun: false,
      code_url: 'https://api.aijingtou.com/static/index/qrcode_1.jpg?t=' + new Date().getTime(),
    }
  },
  methods: {
    scroll() {
      let scrolled = this.$refs.appContent.scrollTop;
      Bus.$emit("appScroll", scrolled);
    },
    handleServer() {
      window.open('https://tb.53kf.com/code/client/d270397d797a6ee05fe78c0130fe30b72/1', '_blank', 'location=no,width=800,height=600');
    }
  },
  created() {
    this.flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    if(this.flag) {
      document.body.style.setProperty('--min-width','')
    }
  },
  mounted: function () {
    this.$refs.appContent.addEventListener("scroll", this.scroll);
  },
};
</script>



<style src="@/assets/css/reset.css"></style>
<style lang="scss" src="@/assets/css/common.scss"></style>

<style scoped lang="scss">
#app {
  .ajt_server {
    width: 55px;
    height: 244px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    right: 3px;
    bottom: calc(50% - 122px);
    z-index: 999999999;
    background-color: #FFFFFF;
    border-radius: 5px;
    border: solid 1px #ebeff2;
    .item {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      color: #999999;
      font-size: 14px;
      line-height: 16px;
      position: relative;
      .qun_code {
        width: 161px;
        height: 205px;
        left: -175px;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #ffffff;
        z-index: 5;
        box-shadow: 0px 4px 10px 0px
        rgba(204, 204, 204, 0.3);
        border-radius: 9px;
        border: solid 1px #ebeff2;
        color: #333;
        img {
          width: 129px;
          height: 128px;
          margin-bottom: 13px;
          margin-top: 15px;
        }
      }
      .QR_code {
        position: absolute;
        width: 158px;
        height: 179px;
        left: -160px;
        background: url("assets/img/dataView/ma_new.png") center center;
        background-size: cover;
        z-index: 5;
      }
      img {
        margin-bottom: 5px;
      }
      &:hover {
        color: #1f81f8;
        .default_img {
          display: none;
        }
        .active_img {
          display: block;
        }
      }
      .default_img {
        display: block;
      }
      .active_img {
        display: none;
      }
    }
  }
}
</style>

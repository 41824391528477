import Vue from 'vue';
import {Toast} from "vant";
import PinganAna from "pingansec-vue-ana";
import router from '../router'
import {
    get_user_info
} from '@/api/common'

const COMMON = new Vue({
    data() {
        return {
            regPos: /^\d+(\.\d+)?$/, //非负整数
            regNeg: /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/, //负整数
            telReg: /^1[3456789]\d{9}$/, // 手机号码有误，请重填
            psdReg: /^[\w_]{6,16}$/, // 请输入6~16位的字母或数字
        }
    },
    created() {
        Array.prototype.remove = function (val) {
            let index = this.indexOf(val);
            if (index > -1) {
                this.splice(index, 1);
            }
        };
    },
    methods: {
        getUserInfo() {
          let p = new Promise((resolve, reject) => {
              get_user_info().then(res => {
              if(res.data.result_code == 0) {
                resolve(res.data.data);
              }
            })
          })
          return p;
        },
        getCompanyDetails(digest, point) {
            if(point) {
                PinganAna.fire(point)
            }
            const { href } = router.resolve({
                name: "companyDetails",
                query: {
                    digest
                }
            });
            window.open(href, '_blank');
        },
        jump(url, point) {
            if(point) {
                PinganAna.fire(point)
            }
            let re = new RegExp( /(http|https):\/\/([\w.]+\/?)\S*/);
            if(!re.test(url)) {
                url = 'http://' + url;
            }
            window.open(url, '_blank');
        },
        sourceTurn(item) {
            let sourceMap = new Map([
              ['aisou_banner', 1],
              ['aisou_detail', 2],
              ['message', 3],
            ]);
            return sourceMap.get(item)
        },
        platformTurn(id) {
            let platformMap = new Map([
                [1,'百度PC'],
                [2,'百度移动'],
                [3,'360PC'],
                [4,'360移动'],
                [5,'搜狗PC'],
                [6,'搜狗移动'],
                [7,'神马搜索'],
            ]);
            return platformMap.get(id)
        },
        isNumber(val) {
            return this.regPos.test(val) || this.regNeg.test(val)
        },
        isDisabled(dom, className) {
            return dom.classList.value.split(" ").includes(className)
        },
        localArrayFive(txt) {
            let arr = localStorage.getItem('aisou_sdk_search_options')
            if (arr && arr.indexOf(txt) > -1) {
                return false
            }
            arr = arr ? arr.split(',') : []
            arr.unshift(txt)
            arr = arr.slice(0, 5).join(',')
            localStorage.setItem('aisou_sdk_search_options', arr)
        },
        dateFormat(fmt, date) {
            let ret;
            const opt = {
                "Y+": date.getFullYear().toString(), // 年
                "m+": (date.getMonth() + 1).toString(), // 月
                "d+": date.getDate().toString(), // 日
                "H+": date.getHours().toString(), // 时
                "M+": date.getMinutes().toString(), // 分
                "S+": date.getSeconds().toString() // 秒
            };
            for (let k in opt) {
                ret = new RegExp("(" + k + ")").exec(fmt);
                if (ret) {
                    fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
                };
            };
            return fmt;
        },
        getBeforeDay(num) {
            let date1 = new Date();
            let time1 = date1.getFullYear() + "-" + ((date1.getMonth() + 1 > 9) ? (date1.getMonth() + 1) : '0' + (date1.getMonth() + 1)) + "-" + (date1.getDate() > 9 ? date1.getDate() : '0' + date1.getDate());
            let date2 = new Date(new Date().getTime() - num * 24 * 60 * 60 * 1000)
            let time2 = date2.getFullYear() + "-" + ((date2.getMonth() + 1 > 9) ? (date2.getMonth() + 1) : '0' + (date2.getMonth() + 1)) + "-" + (date2.getDate() > 9 ? date2.getDate() : '0' + date2.getDate());
            return num === 0 ? time1 : time2;
        },
        telRegFn(tel) {
            return new Promise((res, rej) => {
                res(tel ? (this.telReg.test(tel) ? false : '手机号码有误，请重填') : '请输入手机号！')
            });
        },
        hideTel(tel) {
            var mobile = String(tel)
            var reg = /^(\d{3})\d{4}(\d{4})$/
            return mobile.replace(reg, '$1****$2')
        },
        psdRegFn(psd) {
            return new Promise((res, rej) => {
                res(psd ? (this.psdReg.test(psd) ? false : '请输入6~16位的字母或数字') : '请输入密码！')
            });
        },
        getUnicodeLength(str) {
            let i = 0;
            let c = 0.0;
            let unicode = 0;
            let len = 0;
            if (str == "" || str == null) {
                return 0;
            }
            len = str.length;
            for (i = 0; i < len; i++) {
                unicode = str.charCodeAt(i);
                if (unicode < 127) {
                    c += 1;
                } else {
                    c += 2;
                }
            }
            return c;
        },
    }
})

export default {
    COMMON,
    install(Vue) {
        Vue.prototype.$C = COMMON;
    }
}

import axios from 'axios'

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    },
})

// 添加请求拦截器
service.interceptors.request.use(function (config) {
    config.headers['X-AJT-TOKEN'] = localStorage.getItem('ajt_token')
    if (config.header) {
        config.headers['Content-Type'] = config.header['Content-Type']
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// 添加应响拦截器
service.interceptors.response.use(function (res) {
    if (res.data.result_code == 120000) {
        if(window.location.pathname != '/') {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            if(flag) {
                window.location.href = '/login_h5'
            } else {
                window.location.href = '/login'
            }
            return false
        }
    }
    return res;
}, function (error) {
    return Promise.reject(error);
});

export default service

<template>
  <!--    封装了 three types of button : 'default', 'primary', 'secondary'  -->
  <div
    :class="type"
    :style="{ width: width + 'px', height: height + 'px', cursor: disable?'not-allowed':'pointer', opacity:  disable?'0.5':'1'}"
    @click="handleClick"
  >
    <div class="container">
      <div class="picture">
        <slot name="img"></slot>
      </div>
      <div class="text" :style="{ fontSize: fontsize + 'px' }">
        <slot name="text"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myButton",
  props: ["type", "width", "height", "fontsize", "disable"],
  data() {
    return {};
  },
  methods: {
    handleClick() {
      if (!this.disable) {
        this.$emit("click");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.default {
  &:hover {
    background-color: #e9f3fd;
  }
  &:active {
    background-color: #f6f9fe;
  }
  display: inline-flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 6px;
  border: solid 1px #318bf9;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 14px;
  .container {
    display: flex;
    margin: 0 auto;
    align-items: center;
    img {
      margin-right: 5px;
    }
    .picture {
      display: inline-block;
    }
    .text {
      display: inline-block;
      color: #318bf9;
    }
  }
}
.primary {
  &:hover {
    background-color: #1c74de;
  }
  &:active {
    background-color: #358ef7;
  }
  display: inline-flex;
  align-items: center;
  background-color: #1e81f8;
  border-radius: 6px;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 14px;
  .container {
    display: flex;
    margin: 0 auto;
    align-items: center;
    img {
      margin-right: 5px;
    }
    .picture {
      display: inline-block;
    }
    .text {
      display: inline-block;
      color: #ffffff;
    }
  }
}
.secondary {
  &:hover {
    background-color: #f9fafc;
    .container .text {
      color: #828282;
    }
  }
  &:active {
    background-color: #f9fafc;
    .container .text {
      color: #9f9f9f;
    }
  }
  display: inline-flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 6px;
  border: solid 1px #ebeff2;
  cursor: pointer;
  box-sizing: border-box;
  font-size: 14px;
  .container {
    display: flex;
    margin: 0 auto;
    align-items: center;
    img {
      margin-right: 5px;
    }
    .picture {
      display: inline-flex;
      align-items: center;
    }
    .text {
      display: inline-block;
      color: #a2a2a2;
    }
  }
}
</style>

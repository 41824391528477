import http from '@/utils/http'

// 用户
export function get_user_info(data) {
    return http({
        url: '/u-base/ac-get_user_info',
        method: 'get',
        params: data
    })
}

///微信授权-token凭证
export function wx_h5_auth(data) {
    return http({
        url: '/u-account/ac-wx_h5_auth',
        method: 'get',
        params: data
    })
}

//短信通知接口
export function message_h5_auth(data) {
    return http({
        url: '/u-account/ac-message_h5_auth',
        method: 'get',
        params: data
    })
}

//用户需要做的事情
export function get_user_todo_config(data) {
    return http({
        url: '/u-base/ac-get_user_todo_config',
        method: 'get',
        params: data
    })
}

//用户设置配置项(监测列表勾选)
export function get_user_setting_monitor_company(data) {
    return http({
        url: '/u-base/ac-get_user_setting_monitor_company',
        method: 'get',
        params: data
    })
}

//批量企业监控设置
export function do_batch_monitor(data) {
    return http({
        url: '/u-base/ac-do_batch_monitor',
        method: 'get',
        params: data
    })
}

<template>
  <div id="no-data">
    <slot name="img">
      <img src="@/assets/img/icon/no_data.png" alt="" />
    </slot>
    <slot name="text">
      <p>{{ title ? title : "暂无相关数据" }}</p>
    </slot>
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style lang="scss" scoped>
#no-data {
  @extend .width-normal;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  margin-bottom: 50px;
  img {
    @extend .width-normal;
    width: 103px;
    margin-bottom: 20px;
  }
  p {
    @extend .font-normal;
    line-height: 30px;
    color: $c999999;
  }
}
</style>
